import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OrbeSlideInfo } from 'src/app/interfaces/OrbeSlideInfo';

@Component({
  selector: 'app-orbe-slider',
  templateUrl: './orbe-slider.component.html',
  styleUrls: ['./orbe-slider.component.scss'],
})
export class OrbeSliderComponent implements OnInit {
  @Output() itemClicked = new EventEmitter<any>();
  @Output() seeMoreClicked = new EventEmitter<any>();
  @Input() slides: OrbeSlideInfo[] = [];
  @Input() title: string;
  @Input() seeMoreText: string;
  @Input() options? = {
    slidesPerView: 2.7,
    spaceBetween: 20,
    freeMode: false,
  };

  constructor() {}

  ngOnInit() {
    //console.log(this.slides);
  }

  itemClickedEvent(itemClickedId) {
    this.itemClicked.emit(itemClickedId);
  }
  seeMoreClickedEvent(seeMoreClicked) {
    this.seeMoreClicked.emit(seeMoreClicked);
  }
}
