import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { InputOptionsInterface } from 'src/app/interfaces/InputOptionsInterface';

@Injectable({
  providedIn: 'root',
})
export class FormService {
  public errorMessage = '';

  constructor() {}

  public getMessageError(form: FormGroup, control: string): string {
    const f = form.get(control);

    if (f.errors) {
      const errors = {
        required: 'Este campo es requerido.',
        email: 'Por favor ingrese un correo válido.',
        minlength: `Mínimo de caracteres ${f.errors.minlength?.requiredLength}`,
        maxlength: `Máximo de caracteres ${f.errors.maxlength?.requiredLength}`,
        pattern: 'No cumple con el carácter requerido.',
        invalidYear: 'El año introducido no es válido.',
        unknown: 'Error desconocido',
      };
      return errors[Object.keys(f.errors)[0]];
    }

    return '';
  }

  public isDirty(form: FormGroup, control: string): boolean {
    return form.get(control).dirty;
  }

  /* Validators */
  public validatePassword(form: FormGroup) {
    if (
      form.get('password').value !== form.get('password_confirmation').value
    ) {
      return { dontMatch: true };
    }
    return null;
  }

  public validateEmail(form: FormGroup) {
    if (form.get('email').value !== form.get('confirmateEmail').value) {
      return { dontEmail: true };
    }
    return null;
  }

  public dateValidation(control: AbstractControl) {
    const actualDate = new Date();
    const myDate = new Date(control.value);
    actualDate.setSeconds(0);
    actualDate.setMilliseconds(0);

    if (myDate < actualDate) {
      return { invalidDate: true };
    }

    return null;
  }

  public yearValidation(control: AbstractControl) {
    const actualYear = new Date().getFullYear();
    //console.log(actualYear);
    const myYear = control.value.exp_year;
    //console.log(myYear);
    if (myYear < actualYear) {
      return { invalidYear: true };
    }
    return null;
  }

  public getInputOptions(
    form: FormGroup,
    formControlName: AbstractControl,
    label: string,
    placeholder: string,
    type: string
  ): InputOptionsInterface {
    return {
      controlName: formControlName,
      form,
      label,
      placeholder,
      type,
    };
  }
}
