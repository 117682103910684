import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { RegisterOfferBubbleOption } from 'src/app/interfaces/RegisterOfferBubbleOption';

@Component({
  selector: 'app-register-offer-bubbles',
  templateUrl: './register-offer-bubbles.component.html',
  styleUrls: ['./register-offer-bubbles.component.scss'],
})
export class RegisterOfferBubblesComponent implements OnInit {
  @Input() withTitle = true;
  @Input() options: RegisterOfferBubbleOption[];
  @Output() planSelectedEvent = new EventEmitter();
  constructor(private router: Router) {}

  ngOnInit() {}

  goToSignUp(membership) {
    this.router.navigate(['/sign-up'], { queryParams: { membership } });
  }
  planSelected(membership){
    //console.log(membership);
    this.planSelectedEvent.emit(membership);
  }
}
