import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-social-media-authentication',
  templateUrl: './social-media-authentication.component.html',
  styleUrls: ['./social-media-authentication.component.scss'],
})
export class SocialMediaAuthenticationComponent implements OnInit {
  @Output() nextStep = new EventEmitter<any>();

  socialMediaOptions = [
    {
      url:'assets/imgs/social-media-auth/facebook.png',
    },
    {
      url:'assets/imgs/social-media-auth/google.png',
    },
    {
      url:'assets/imgs/social-media-auth/linkedin.png',
    },
    {
      url:'assets/imgs/social-media-auth/windows.png',
    },
  ];

  constructor() { }

  ngOnInit() {}

  nextStepEvent() {
    this.nextStep.emit();
  }

}
