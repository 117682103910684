// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  analyticsKey: '',
  apiEndpoint: 'https://apiqa.orbevida.com/api/v1/',
  app:'orbe',
  appLink: 'https://afiliadosqa.orbevida.com/#/',
  facebookPixelKey: '235102417362034',
  firebaseSenderId:'249467261',
  mode: 'qa',
  oneSignalAppId:'b9be6c91-4d08-4e29-93c1-ff0bdbe0e075',
  production: true,
  sentryDsn: 'https://978c152f46a4469ea7eda8e585bf6030@sentry.io/1260069',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
