import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-validation-error-label',
  templateUrl: './validation-error-label.component.html',
  styleUrls: ['./validation-error-label.component.scss'],
})
export class ValidationErrorLabelComponent implements OnInit {
  @Input() message: string;
  constructor() { }

  ngOnInit() {}

}
