import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-full-size-banner',
  templateUrl: './full-size-banner.component.html',
  styleUrls: ['./full-size-banner.component.scss'],
})
export class FullSizeBannerComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
