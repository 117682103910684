import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-alphabetical-ordered-list',
  templateUrl: './alphabetical-ordered-list.component.html',
  styleUrls: ['./alphabetical-ordered-list.component.scss'],
})
export class AlphabeticalOrderedListComponent implements OnInit {
  @Input() items = [];
  @Output() itemClicked = new EventEmitter<any>();
  orderedItems;
  alphabet = [
    'a',
    'b',
    'c',
    'd',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z',
  ];
  constructor() {}

  ngOnInit() {
    this.orderedItems = this.alphabet.map((letter) => {
      const letterItems = {
        letter,
        items: this.getItemsOfLetter(letter),
      };
      return letterItems;
    });
    //console.log(this.orderedItems);

  }
  getItemsOfLetter(letter) {
    return this.items.filter((item) => item.title.toLowerCase().charAt(0) === letter);
  }

  itemClickedEvent() {
    this.itemClicked.emit();
  }
}
