import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-review-card',
  templateUrl: './review-card.component.html',
  styleUrls: ['./review-card.component.scss'],
})
export class ReviewCardComponent implements OnInit {
  @Input() reviewCardInfo: ReviewCardInfo;

  constructor() {}

  ngOnInit() {
    //console.log(this.reviewCardInfo);
  }
}

export interface ReviewCardInfo {
  url: string;
  title: string;
  subtitle: string;
  stars?: number;
  rating: number;
  numberOfTestimonials: number;
}
