import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import SwiperCore, { Pagination, Swiper, Virtual } from 'swiper';
import { SwiperComponent } from 'swiper/angular';
@Component({
  selector: 'app-sponsor-slides',
  templateUrl: './sponsor-slides.component.html',
  styleUrls: ['./sponsor-slides.component.scss'],
})
export class SponsorSlidesComponent implements OnInit {
  @ViewChild('slides', { static: false }) slides?: SwiperComponent;
  @Input() sponsors = [];

  constructor(private router: Router) {}

  ngOnInit() {
    //console.log(this.sponsors);
  }

  public continue() {
    this.slides.swiperRef.slideNext(500);
  }

  goToSponsorMemberships() {
    this.router.navigate(['sign-up-memberships']);
  }
}
