import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './layout/header/header.component';
import { IonicModule } from '@ionic/angular';
import { ContentTopBorderComponent } from './layout/content-top-border/content-top-border.component';
import { FilterHeaderComponent } from './layout/filter-header/filter-header.component';
import { ReactiveFormsModule } from '@angular/forms';
import { InputComponent } from './forms/input/input.component';
import { ValidationErrorLabelComponent } from './forms/validation-error-label/validation-error-label.component';
import { SponsorSlidesComponent } from './slides/sponsor-slides/sponsor-slides.component';
import { SwiperModule } from 'swiper/angular';
import { SponsorSlideComponent } from './slides/sponsor-slide/sponsor-slide.component';
import { RegisterOfferBubblesComponent } from './register-offer-bubbles/register-offer-bubbles.component';
import { SocialMediaAuthenticationComponent } from './social-media-authentication/social-media-authentication.component';
import { BenefitLabelComponent } from './benefit-label/benefit-label.component';
import { ReviewCardComponent } from './review-card/review-card.component';
import { PopularSectionComponent } from './popular-section/popular-section.component';
import { FullSizeBannerComponent } from './full-size-banner/full-size-banner.component';
import { OrbeSlideComponent } from './orbe-slide/orbe-slide.component';
import { OrbeSliderComponent } from './orbe-slider/orbe-slider.component';
import { RouterModule } from '@angular/router';
import { MosaicItemComponent } from './lists/mosaic-item/mosaic-item.component';
import { ContactFloatingButtonsComponent } from './contact-floating-buttons/contact-floating-buttons.component';
import { ListItemComponent } from './lists/list-item/list-item.component';
import { AlphabeticalOrderedListComponent } from './lists/alphabetical-ordered-list/alphabetical-ordered-list.component';
import { DetailedItemComponent } from './lists/detailed-item/detailed-item.component';
import { FilterSegmentComponent } from './filter-segment/filter-segment.component';

const components = [
  AlphabeticalOrderedListComponent,
  ContactFloatingButtonsComponent,
  ContentTopBorderComponent,
  DetailedItemComponent,
  BenefitLabelComponent,
  FilterHeaderComponent,
  FilterSegmentComponent,
  FullSizeBannerComponent,
  HeaderComponent,
  InputComponent,
  ListItemComponent,
  MosaicItemComponent,
  OrbeSlideComponent,
  OrbeSliderComponent,
  PopularSectionComponent,
  RegisterOfferBubblesComponent,
  ReviewCardComponent,
  SocialMediaAuthenticationComponent,
  SponsorSlideComponent,
  SponsorSlidesComponent,
  ValidationErrorLabelComponent,
];
@NgModule({
  declarations: components,
  exports: components,
  imports: [
    IonicModule,
    CommonModule,
    ReactiveFormsModule,
    SwiperModule,
    RouterModule,
  ],
})
export class ComponentsModule {}
