import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ReviewCardInfo } from '../review-card/review-card.component';

@Component({
  selector: 'app-popular-section',
  templateUrl: './popular-section.component.html',
  styleUrls: ['./popular-section.component.scss'],
})
export class PopularSectionComponent implements OnInit {
  @Output() itemClicked = new EventEmitter<any>();
  @Output() seeMoreClicked = new EventEmitter<any>();

  @Input() title: string;
  @Input() seeMoreText: string;
  @Input() popularItems: ReviewCardInfo[];

  constructor() {}

  ngOnInit() {
    //console.log(this.popularItems);
  }
  itemClickedEvent(itemClickedId) {
    this.itemClicked.emit(itemClickedId);
  }
  seeMoreClickedEvent(seeMoreClicked) {
    this.seeMoreClicked.emit(seeMoreClicked);
  }
}
