import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-mosaic-item',
  templateUrl: './mosaic-item.component.html',
  styleUrls: ['./mosaic-item.component.scss'],
})
export class MosaicItemComponent implements OnInit {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() url: string;


  constructor() {}

  ngOnInit() {}
}
