import { Component, Input, OnInit, Output } from '@angular/core';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() color = '';
  @Input() withLogo = true;
  @Input() buttonsColor;
  logo: string;
  constructor(private navCtrl: NavController) {}

  ngOnInit() {
    if (this.color === 'custom-primary') {
      this.logo = '/assets/orbe-logo-2.svg';
    } else {
      this.logo = '/assets/orbe-logo-primary.svg';
    }
  }

  goToHome(){
    this.navCtrl.navigateRoot('/app/home');
  }
}
