import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-detailed-item',
  templateUrl: './detailed-item.component.html',
  styleUrls: ['./detailed-item.component.scss'],
})
export class DetailedItemComponent implements OnInit {
  @Input() url;
  @Input() title;
  @Input() shape = 'square';
  @Input() lines = '';
  @Output() itemClicked = new EventEmitter();

  constructor() { }

  ngOnInit() {
    //console.log(this.shape);
  }

  itemClickedEvent(item){
    this.itemClicked.emit(item);
  }

}
