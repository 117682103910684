import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-benefit-label',
  templateUrl: './benefit-label.component.html',
  styleUrls: ['./benefit-label.component.scss'],
})
export class BenefitLabelComponent implements OnInit {
  @Input() text = '';
  constructor() {}

  ngOnInit() {}
}
