import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-content-top-border',
  templateUrl: './content-top-border.component.html',
  styleUrls: ['./content-top-border.component.scss'],
})
export class ContentTopBorderComponent implements OnInit {
  @Input() color = '';
  colorStyle;
  constructor() {}

  ngOnInit() {
    if(this.color){
      this.colorStyle = `background-color: var(--ion-color-${this.color});`;
    }
  }
}
