import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'welcome',
    loadChildren: () => import('./pages/welcome/welcome.module').then( m => m.WelcomePageModule)
  },
  {
    path: 'sign-in',
    loadChildren: () => import('./pages/auth/sign-in/sign-in.module').then( m => m.SignInPageModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/auth/forgot-password/forgot-password.module').then( m => m.ForgotPasswordPageModule)
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./pages/auth/reset-password/reset-password.module').then( m => m.ResetPasswordPageModule)
  },
  {
    path: 'sign-up',
    loadChildren: () => import('./pages/auth/sign-up/sign-up.module').then( m => m.SignUpPageModule)
  },
  {
    path: 'sign-up-names',
    loadChildren: () => import('./pages/auth/sign-up-names/sign-up-names.module').then( m => m.SignUpNamesPageModule)
  },
  {
    path: 'sign-up-beneficiaries',
    // eslint-disable-next-line max-len
    loadChildren: () => import('./pages/auth/sign-up-beneficiaries/sign-up-beneficiaries.module').then( m => m.SignUpBeneficiariesPageModule)
  },
  {
    path: 'sign-up-offer',
    loadChildren: () => import('./pages/auth/sign-up-offer/sign-up-offer.module').then( m => m.SignUpOfferPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'sign-up-pro-plan',
    loadChildren: () => import('./pages/auth/sign-up-pro-plan/sign-up-pro-plan.module').then( m => m.SignUpProPlanPageModule)
  },
  {
    path: 'sign-up-pro-benefits',
    loadChildren: () => import('./pages/auth/sign-up-pro-benefits/sign-up-pro-benefits.module').then( m => m.SignUpProBenefitsPageModule)
  },
  {
    path: 'sign-up-payment',
    loadChildren: () => import('./pages/auth/sign-up-payment/sign-up-payment.module').then( m => m.SignUpPaymentPageModule)
  },
  {
    path: 'sign-up-pro-beneficiaries',
    loadChildren: () => import('./pages/auth/sign-up-pro-beneficiaries/sign-up-pro-beneficiaries.module').then( m => m.SignUpProBeneficiariesPageModule)
  },
  {
    path: 'sign-up-completed',
    loadChildren: () => import('./pages/auth/sign-up-completed/sign-up-completed.module').then( m => m.SignUpCompletedPageModule)
  },
  {
    path: 'sign-up-memberships',
    loadChildren: () => import('./pages/auth/sign-up-memberships/sign-up-memberships.module').then( m => m.SignUpMembershipsPageModule)
  },
  {
    path: 'search',
    loadChildren: () => import('./pages/search/search.module').then( m => m.SearchPageModule)
  },
  {
    path: 'specialist',
    loadChildren: () => import('./pages/specialist/specialist.module').then( m => m.SpecialistPageModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
