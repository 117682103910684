import { Component, OnInit } from '@angular/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { NavController } from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit{
  constructor(private navCtrl: NavController, translate: TranslateService, private platform: Platform) {
    translate.setDefaultLang('es');
    this.platform.backButton.subscribeWithPriority(10, () => {
      this.navCtrl.back();
    });
  }

  async ngOnInit() {
    const firstTimeOpened = localStorage.getItem('first_time_opened');
    //console.log(firstTimeOpened);
    if(firstTimeOpened === 'true' || firstTimeOpened === null){
      this.navCtrl.navigateRoot('welcome');
    }/* else{
      this.navCtrl.navigateRoot('app');
    } */
    //console.log('holamen');
    await SplashScreen.show({
      showDuration: 2000,
      autoHide: true
    });
  }
}
