import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-filter-header',
  templateUrl: './filter-header.component.html',
  styleUrls: ['./filter-header.component.scss'],
})
export class FilterHeaderComponent implements OnInit {
  @Input() color = '';
  @Input() form?: FormGroup;
  @Input() formControlNames: FormControlOptions[] = [];
  @Output() sendFormEvent = new EventEmitter();
  backgroundStyle = '';

  constructor() {}

  ngOnInit() {
    //console.log(this.form.controls.specialty);
    this.backgroundStyle = `background-color: var(--ion-color-${this.color})`;
  }

  keyUpEvent(event){
    this.sendFormEvent.emit(true);
    //console.log(event.target.value);
  }
}

export interface FormControlOptions {
  controlName: string;
  type: string;
  placeholder: string;
  icon: string;
}
