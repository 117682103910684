import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-orbe-slide',
  templateUrl: './orbe-slide.component.html',
  styleUrls: ['./orbe-slide.component.scss'],
})
export class OrbeSlideComponent implements OnInit {
  @Input() slide;
  @Output() itemClicked = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {}

  itemClickedEvent() {
    this.itemClicked.emit(this.slide?.id);
  }
}
