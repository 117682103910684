import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-sponsor-slide',
  templateUrl: './sponsor-slide.component.html',
  styleUrls: ['./sponsor-slide.component.scss'],
})
export class SponsorSlideComponent implements OnInit {
  @Input() sponsor;
  @Output() itemClicked = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  itemClickedEvent(){
    this.itemClicked.emit(this.sponsor?.id);
  }

}
