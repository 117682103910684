import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';
import { InputOptionsInterface } from 'src/app/interfaces/InputOptionsInterface';
import { FormService } from 'src/app/services/form/form.service';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  viewProviders: [
    {
        provide: ControlContainer,
        useExisting: FormGroupDirective
    }
]
})
export class InputComponent implements OnInit {
  @Input() inputOptions: InputOptionsInterface;
  @Output() inputChange = new EventEmitter();

  public formControlName: string;
  constructor(public formService: FormService) {}

  ngOnInit() {
    //console.log(this.inputOptions.controlName);
    //console.log( this.getControlName());
    this.formControlName = this.getControlName();
  }

  public inputChangeEvent(event) {
    this.inputChange.emit(event);
  }

  public getControlName() {
    const formGroup = this.inputOptions.controlName.parent.controls;
    return (
      Object.keys(formGroup).find(
        (name) => this.inputOptions.controlName === formGroup[name]
      ) || ''
    );
  }
}
