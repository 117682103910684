import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-filter-segment',
  templateUrl: './filter-segment.component.html',
  styleUrls: ['./filter-segment.component.scss'],
})
export class FilterSegmentComponent implements OnInit {
  selectedFilter='all';

  constructor() { }

  ngOnInit() {}

  selectFilter(filter){
    this.selectedFilter = filter;
  }

}
